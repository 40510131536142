export const about = {
    title1: "Behold the Enchanted Surf Foretellings of Oceanorb.org",
    paragraph1: `Greetings, noble seeker of the ocean's arcane whispers and divine swells! In the hallowed realms of Oceanorb.org, where the ancient tides and celestial winds conspire, we present to you the sacred surf forecasts, drawn from the enigmatic depths of the sea and the heavens above.`,
  
    title2: "A Chronicle of Tides and Waves",
    paragraph2: `Within these venerable scrolls of wisdom, we unveil the surf forecasts that traverse time and tide, offering you a glimpse into the ocean's mysterious dance. Each forecast is meticulously crafted, revealing the arcane patterns of waves, the celestial alignments, and the whispered secrets of the sea. Our predictions are born from the confluence of ancient mariner lore and modern divination, ensuring that every forecast is imbued with both mystic reverence and empirical precision.`,
  
    title3: "The Ocean's Mysteries Unveiled",
    paragraph3: `Prepare thyself for an odyssey through the shifting tides and rolling swells that define the very heart of surf lore. Our enchanted forecasts reveal the depths of the ocean's intentions—its rhythm, its capricious nature, and the hidden currents that lie beneath. Whether thou art a valiant surf knight seeking epic rides or a humble wanderer yearning for tranquil shores, our predictions shall guide thee with the clarity of the crystal ball and the wisdom of the ancients.`,
  
    title4: "Why Trust Our Mystical Predictions?",
    paragraph4: `At Oceanorb.org, our dedication to the craft of surf forecasting transcends mere numerical predictions. We weave together the ancient maritime art of weather divination with cutting-edge technology, offering a tapestry of information that is both ancient and avant-garde. Our insights are derived from a deep understanding of the ocean's mystic rhythms, the stars' celestial pathways, and the atmospheric conditions that herald the waves' approach.`,
  
    title5: "Embrace the Ocean's Call",
    paragraph5: `As thou embark on thy surf journey, let Oceanorb.org be thy compass and guide. Our forecasts shall arm thee with knowledge and foresight, allowing thee to navigate the tumultuous waters with the grace of a seasoned mariner. With each forecast, embrace the wisdom of the waves and the whispers of the wind, and ride the ocean's mysteries with confidence and awe.`,
  
    title6: "Join the Guild of Surf Seekers",
    paragraph6: `Visit Oceanorb.org to partake in the sacred knowledge of our surf forecasts. Become one with the ocean's eternal dance and unlock the secrets that lie beneath the surface. Our realm awaits, and the waves call to thee. Answer the call with Oceanorb.org—where ancient lore meets the boundless sea.`,
    legal1: "Legal Disclaimer: A Covenant of Clarity",


    legal2: "Important Notice: Use of Surf Forecasts and Tide Information",
  
  
    legal3: `The surf forecasts and tide information provided by Oceanorb.org are crafted with the utmost care and precision, drawing from both ancient maritime wisdom and contemporary meteorological data. However, these forecasts and related data are intended for informational and recreational purposes only.`,
  
  
    legal4: "No Guarantee of Accuracy",
  
  
    legal5: `Oceanorb.org makes no guarantees, representations, or warranties, whether express or implied, regarding the accuracy, reliability, or completeness of the surf forecasts or tide information. The ocean is a dynamic and unpredictable environment, and conditions may change rapidly and without warning.`,
  
  
    legal6: "Not for Navigation or Safety",
  
  
    legal7: `The surf forecasts and tide information provided on Oceanorb.org are not designed for, nor should they be used for, navigational purposes or any critical safety decisions. They are not a substitute for professional advice or services. For navigation, safety, and other critical needs, please consult with licensed professionals and authoritative sources.`,
  
  
    legal8: "Limitation of Liability",
  
  
    legal9: `In no event shall Oceanorb.org, its affiliates, or its contributors be liable for any direct, indirect, incidental, consequential, or punitive damages arising from or related to the use of or reliance on the surf forecasts or tide information provided. Users of this information assume all risks associated with their use and agree to hold harmless Oceanorb.org from any claims, losses, or damages.`,
  
  
    legal10: "Acceptance of Terms",
  
  
    legal11: `By accessing and using the surf forecasts and tide information provided on Oceanorb.org, you acknowledge that you have read, understood, and agree to the terms of this legal disclaimer. Your continued use of the site constitutes your acceptance of these terms.`,
  
  
    legal12: "Contact Us",
  
  
    legal13: `For any questions or concerns regarding this legal disclaimer, please contact us at info@oceanorb.org.`,

    sources: "Primary Data Sources",

    dataSources: [
      "DWD ICON Wave Model",
      "DWD GWAM",
      "MeteoFrance MFWAM",
      "NCEP GFS Wave",
      "Open-Meteo (data processing)",
      "Perth5 (data processing) / NASA Goddard Space Flight Center "
  ],

  };